import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CoachingPage = () => (
  <Layout>
    <Seo
      title="Coaching | Verhaltenstherapie München | Praxis für Psychotherapie"
      description="Psychologische Beratung & Coaching in Phasen von Veränderung, lebensrelevanten Fragestellungen, Stressmanagement & Entwicklung der Persönlichkeit."
    />

    <div className="relative">
      <div className="w-full h-[32rem]">
        <StaticImage className="w-full h-full" src="../assets/images/coaching-und-beratung-stephan.jpg" alt="" />
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="lg:text-4xl">Coaching</h1>
            {/* <div>Diese Seite befindet sich im derzeit im Aufbau. Bei Fragen wenden Sie sich gerne an mich</div> */}
          </div>
        </div>
      </div>
    </div>
    
    <div className="my-12 boxed max-w-prose generic-page">
      <p>Nicht immer liegen Symptome im Sinne einer psychischen Erkrankung vor. Befinden Sie sich in einer Phase der Veränderung, der Anpassung, oder möchten Sie Ihre Persönlichkeit im privaten oder beruflichen Umfeld weiterentwickeln? Haben Sie als Ziel, Ihr Stressmanagement zu verbessern oder stehen Sie vor einer schwierigen Entscheidungsfindung? Auch im Bereich Selbstfürsorge, Work-Life-Balance und Selbstwertstärkung können psychologische Beratung und Coaching entscheidende Impulse im Erreichen Ihrer Ziele geben. Im Ausbau sozialer Kompetenzen werden Fähigkeiten zur Konfliktlösung und hilfreiche Kommunikationstechniken erarbeitet. In einem ausführlichen Erstgespräch in meiner psychologischen Praxis in München nehme ich mir Zeit für Ihr Anliegen und wir entwickeln gemeinsam ein individuell auf Sie zugeschnittenes Vorgehen.</p>
    </div>

  </Layout>
)

export default CoachingPage
